import { Textarea as Cmpt} from "@/components/ui/textarea";

function Textarea({ className, ref, ...props }) {
	return <Cmpt 
                className={className}
                ref={ref}
                {...props}
           />;
}

export default Textarea;
