import PropTypes from 'prop-types';

import { RadioGroup as RadioGroupContainer, RadioGroupItem } from '@/components/ui/radiogroup';

import Label from '@/components/atoms/label';
import Text from '@/components/atoms/text';
import Icon from '@/components/atoms/icon';
import { cn } from '@/lib/utils';
import { useEffect, useState } from 'react';

function RadioGroup({ type, name, options, className, disabled, isHorizontal, value, onValueChange }) {
	const [selectedValue, setSelectedValue] = useState(value ? value : '');

	useEffect(() => {
		setSelectedValue(value ? value : '');
	}, [value]);

	const getAllRadioGroupItems = (items) => {
		let allRadioGroupItems = [];

		if (type === 'card') {
			for (let item of items) {
				allRadioGroupItems.push(
					<div className="relative flex flex-col w-full gap-2" key={`${name}-${item.value}`}>
						<RadioGroupItem type={type} value={item.value} id={`${name}-${item.value}`} className={cn(className)}>
							<Icon name={item.icon} className="text-eis-black" />
							<Label htmlFor={`${name}-${item.value}`} className="font-normal">
								{item.label}
							</Label>
						</RadioGroupItem>
					</div>
				);
			}
		} else {
			for (let item of items) {
				allRadioGroupItems.push(
					<div className="flex flex-col gap-1" key={`${name}-${item.value}`}>
						<div className="flex items-start gap-1">
							<RadioGroupItem value={item.value} id={`${name}-${item.value}`} className={cn(className, 'mt-1')} />
							<Label htmlFor={`${name}-${item.value}`} className="font-normal">
								{item.label}
							</Label>
						</div>

						{/* Note: add a customed .radio-group-item-supporting-text to change the supporting text colour when disabled */}
						{item.text && (
							<Text type="p" variant="normal" className="ml-5 text-corporateGrey radio-group-item-supporting-text">
								{item.text}
							</Text>
						)}
					</div>
				);
			}
		}

		return allRadioGroupItems;
	};

	const onChange = (val) => {
		setSelectedValue(val);
	};

	useEffect(() => {
		// only fire change when form value and internal state out of sync
		if (!selectedValue && value === selectedValue) return;
		typeof onValueChange === 'function' && onValueChange(selectedValue);
	}, [selectedValue]);

	return (
		<RadioGroupContainer
			defaultValue={value || options[0]?.value}
			onValueChange={onChange}
			value={selectedValue}
			className={isHorizontal || type === 'card' ? 'flex-row flex-wrap sm:flex-nowrap' : 'flex-col'}
			disabled={disabled}
			name={name}
		>
			{getAllRadioGroupItems(options)}
		</RadioGroupContainer>
	);
}

export default RadioGroup;

RadioGroup.propTypes = {
	type: PropTypes.oneOf(['default', 'card']).isRequired,
	name: PropTypes.string.isRequired,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			text: PropTypes.string,
			card: PropTypes.string,
		})
	),
	className: PropTypes.string,
	disabled: PropTypes.bool,
	isHorizontal: PropTypes.bool,
	onValueChange: PropTypes.func,
};

RadioGroup.defaultProps = {
	type: 'default',
	name: null,
	options: null,
	className: null,
	disabled: false,
	isHorizontal: false,
};
