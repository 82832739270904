"use client"

import * as React from "react"
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group"
import { Circle } from "lucide-react"

import { cn } from "@/lib/utils"

const RadioGroup = React.forwardRef(({ className, disabled, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root
      className={cn("flex gap-4 radio-group-container", className)}
      {...props}
      ref={ref}
      disabled={disabled}
    />
  )
})
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName

const RadioGroupItem = React.forwardRef(({ type, className, children, ...props }, ref) => {
  if(type === "card") {
    return (
      <RadioGroupPrimitive.Item
        ref={ref}
        className={cn(
          "flex flex-col gap-2 justify-center bg-white items-center rounded-lg w-full h-eis-88 border border-formOutline text-primary",
          // Note: hover
          "hover:bg-corporateGreyLight hover:border-corporateGreyLight",
          // Note: focus-visible
          "ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-formOutlineDark focus-visible:ring-offset-2",
          // Note: disabled
          "disabled:cursor-not-allowed disabled:opacity-50",
          // Note: active
          "aria-[checked=true]:border-2 aria-[checked=true]:border-eis-black aria-[checked=true]:hover:bg-white",
          // Note: add a customed .radio-group-item to change the label colour when disabled
          "radio-group-item",
          className
        )}
        {...props}
      >
        {children}
      </RadioGroupPrimitive.Item>
    )
  } else {
    return (
      <RadioGroupPrimitive.Item
        ref={ref}
        className={cn(
          "aspect-square h-4 w-4 rounded-full border border-formOutline text-primary bg-white",
          // Note: focus-visible
          "ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-formOutlineDark focus-visible:ring-offset-2",
          // Note: disabled
          "disabled:cursor-not-allowed disabled:opacity-50",
          // Note: add a customed .radio-group-item to change the label colour when disabled
          "radio-group-item",
          className
        )}
        {...props}
      >
        <RadioGroupPrimitive.Indicator className="flex items-center justify-center text-corporateBlueBright disabled:opacity-50">
          <Circle className="h-2.5 w-2.5 fill-current text-current" />
        </RadioGroupPrimitive.Indicator>
      </RadioGroupPrimitive.Item>
    )
  }
  
})
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName

export { RadioGroup, RadioGroupItem }
