import React, { useState, useEffect } from 'react';
import { cn } from '@/lib/utils';

const Textarea = React.forwardRef(({ className, onChange, value, ...props }, ref) => {
	const [inputValue, setInputValue] = useState(value ? value : '');

	useEffect(() => {
		setInputValue(value ? value : '');
	}, [value]);

	useEffect(() => {
		// only fire change when form value and internal state out of sync
		if (value === inputValue) return;

		onChange && onChange({ target: { value: inputValue } });
	}, [inputValue]);

	return (
		<textarea
			ref={ref}
			className={cn(
				'flex min-h-eis-116 w-full rounded-md border border-formOutline bg-background px-3 py-2 text-base text-eis-black ring-offset-background placeholder:text-formOutlineDark focus:border-formOutline focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-formOutlineDark focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
				className
			)}
			value={inputValue}
			onChange={(e) => {
				setInputValue(e.target.value);
			}}
			{...props}
		/>
	);
});
Textarea.displayName = 'Textarea';

export { Textarea };
