import React, { useState } from 'react';
import * as SwitchPrimitives from '@radix-ui/react-switch';

import { cva } from 'class-variance-authority';
import { cn } from '@/lib/utils';

const rootVariants = cva(
	'peer inline-flex h-[24px] w-[44px] shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-notificationsSuccess data-[state=unchecked]:bg-formOutline'
);

const thumbVariants = cva(
	'pointer-events-none block h-5 w-5 rounded-full bg-background shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0'
);

const Switch = React.forwardRef(({ className, value, setData, onClick, data, name, ...props }, ref) => {
	const [isChecked, setIsChecked] = useState(value);

	const handleClick = () => {
		setIsChecked(isChecked === 'yes' ? 'no' : 'yes');
		onClick && onClick(isChecked === 'yes' ? 'no' : 'yes');

		// Note: 1. update backend data for <FormItem> in a form
		//       2. "setData" and "data" are passed through <FormItem> in a form
		{
			setData && data && setData(name, data[name] === 'yes' ? 'no' : 'yes');
		}
	};

	return (
		<SwitchPrimitives.Root
			ref={ref}
			className={cn(rootVariants(), className)}
			aria-checked={isChecked === 'yes' ? true : false}
			data-state={isChecked === 'yes' ? 'checked' : 'unchecked'}
			onClick={handleClick}
			{...props}
		>
			<SwitchPrimitives.Thumb className={cn(thumbVariants(), className)} data-state={isChecked === 'yes' ? 'checked' : 'unchecked'} />
		</SwitchPrimitives.Root>
	);
});

Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
