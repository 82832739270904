import React, { useRef, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FormMessage } from '@/components/ui/form';

import { cn } from '@/lib/utils';

const icons = ReactQuill.Quill.import('ui/icons');
icons[
	'undo'
] = `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none"><path fill="#212529" d="M12.5 6.25H4.884l2.242-2.241-.876-.884-3.75 3.75 3.75 3.75.876-.884L4.886 7.5H12.5a3.75 3.75 0 0 1 0 7.5h-5v1.25h5a5 5 0 1 0 0-10Z"/></svg>`;
icons[
	'redo'
] = `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none"><path fill="#212529" d="M7.5 6.25h7.616l-2.242-2.241.876-.884 3.75 3.75-3.75 3.75-.876-.884 2.24-2.241H7.5a3.75 3.75 0 0 0 0 7.5h5v1.25h-5a5 5 0 0 1 0-10Z"/></svg>`;

const RichTextEditor = ({ name, value, onChange, meta }) => {
	const richEditorRef = useRef(null);

	const handleUndo = () => {
		let myEditor = richEditorRef.current.getEditor();
		return myEditor.history.undo();
	};

	const handleRedo = () => {
		let myEditor = richEditorRef.current.getEditor();
		return myEditor.history.redo();
	};

	const modules = useMemo(
		() => ({
			toolbar: {
				container: [
					['undo', 'redo'],
					[{ header: [1, 2, 3, 4, 5, 6, false] }],
					['bold', 'italic', 'underline', 'strike', 'blockquote'],
					[{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
					[{ align: [] }],
					[{ color: [] }],
					['link'],
					['clean'],
				],
				handlers: {
					undo: handleUndo,
					redo: handleRedo,
				},
			},
			history: {
				delay: 2000,
				maxStack: 500,
				userOnly: true,
			},
		}),
		[]
	);

	const formats = ['header', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link'];

	return (
		<div className="w-full max-w-screen-md text-editor">
			<ReactQuill name={name} ref={richEditorRef} theme="snow" value={value} onChange={onChange} modules={modules} formats={formats} className="w-full" />
			<FormMessage
				name={name}
				className="mt-4"
				meta={{
					msg: meta?.msg,
					touched: meta?.touched,
				}}
			/>
		</div>
	);
};

export default RichTextEditor;

RichTextEditor.propTypes = {
	name: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
	meta: PropTypes.object,
};
