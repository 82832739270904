import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select as SelectContainer, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';

function Select({ options, disabled, className, placeholder, value, onValueChange, ...props }) {
	const [selectedValue, setSelectedValue] = useState(value ? value?.toString() : '');

	const getAllSelectItems = (items) => {
		let allSelectItems = [];

		for (let item of items) {
			const valueStr = item.value?.toString();

			allSelectItems.push(
				<SelectItem value={valueStr} key={valueStr}>
					{item.label}
				</SelectItem>
			);
		}

		return allSelectItems;
	};

	const onChange = (val) => {
		setSelectedValue(val);
	};

	// update internal state when external form value changes
	useEffect(() => {
		setSelectedValue(value?.toString());
	}, [value]);

	// update form value when internal state changes
	useEffect(() => {
		if (selectedValue === value) return;
		onValueChange && onValueChange(selectedValue);
	}, [selectedValue]);

	return (
		<SelectContainer disabled={disabled} value={selectedValue} onValueChange={onChange} {...props}>
			<SelectTrigger className={className} data-placeholder={placeholder}>
				{/* Note: the placeholder value is set in before:content-[''] in the select.jsx */}
				<SelectValue placeholder="" />
			</SelectTrigger>

			{/* Note: if the selected item is longer than one line, it will be truncated (setting up in select.js and app.css) */}
			<SelectContent>{getAllSelectItems(options)}</SelectContent>
		</SelectContainer>
	);
}

export default Select;

Select.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		})
	).isRequired,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	placeholder: PropTypes.string,
};
Select.defaultProps = {
	options: null,
	disabled: null,
	className: null,
	placeholder: 'Please select',
};
