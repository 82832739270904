'use client';

import * as React from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';
import { Check, ChevronDown } from 'lucide-react';

import { cn } from '@/lib/utils';

const Select = SelectPrimitive.Root;

const SelectGroup = SelectPrimitive.Group;

const SelectValue = SelectPrimitive.Value;

const SelectTrigger = React.forwardRef(({ className, disabled, children, ...props }, ref) => (
	<SelectPrimitive.Trigger
		ref={ref}
		// Note: 1. add a customed .select-trigger to style the placeholder
		//       2. use "before:content-['']" to add the placeholder text.
		//          (Unable to pass a value through props,so the user won't be able to update the placeholder text.)
		//       3. when a user select a dropdown item (<SelectValue>), then this span element
		//          (it has a white background and it is set in the app.css) will hide/cover the placeholder value
		className={cn(
			'flex h-10 w-full items-center justify-between rounded-md border border-formOutline bg-background px-3 py-2 text-base ring-offset-background focus:border-formOutline focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-formOutlineDark focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 aria-[expanded=true]:ring-2 aria-[expanded=true]:ring-formOutlineDark aria-[expanded=true]:ring-offset-2 text-eis-black',
			// Note: custom classes for adding custom styles (in app.css) to fix some display issues
			'select-trigger truncate-text-for-select',
			// Note: Placeholder is set in the "data-placeholder" attribute, but falls back to "Please select" if the browser doesn't support CSS attr()
			// Note: the underscore ("_") is for replacing a space.
			"before:content-['Please_select'] before:!content-[attr(data-placeholder)]",
			'relative before:absolute  before:top-eis-7 before:left-3 before:text-formOutlineDark before:z-[0]',
			className
		)}
		{...props}
		disabled={disabled}
	>
		{children}
		<SelectPrimitive.Icon asChild>
			<ChevronDown className="w-4 h-4 opacity-50 shrink-0" />
		</SelectPrimitive.Icon>
	</SelectPrimitive.Trigger>
));
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const SelectContent = React.forwardRef(({ className, children, position = 'popper', ...props }, ref) => (
	<SelectPrimitive.Portal>
		<SelectPrimitive.Content
			ref={ref}
			className={cn(
				'relative z-50 max-w-screen-md min-w-[8rem] overflow-hidden rounded-md border bg-popover text-popover-foreground shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 top-[4px]',
				position === 'popper' &&
					'data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1 max-h-[var(--radix-select-content-available-height)]',
				className
			)}
			position={position}
			collisionPadding={20}
			{...props}
		>
			<SelectPrimitive.Viewport
				className={cn('p-1', position === 'popper' && 'h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]')}
			>
				{children}
			</SelectPrimitive.Viewport>
		</SelectPrimitive.Content>
	</SelectPrimitive.Portal>
));
SelectContent.displayName = SelectPrimitive.Content.displayName;

const SelectLabel = React.forwardRef(({ className, ...props }, ref) => (
	<SelectPrimitive.Label ref={ref} className={cn('py-1.5 pl-8 pr-2 text-sm font-semibold', className)} {...props} />
));
SelectLabel.displayName = SelectPrimitive.Label.displayName;

const SelectItem = React.forwardRef(({ className, children, ...props }, ref) => (
	<SelectPrimitive.Item
		ref={ref}
		className={cn(
			'relative flex w-full cursor-default select-none items-center rounded-md py-1.5 pl-8 pr-2 text-eis-black text-xs outline-none focus:bg-eis-light-blue focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
			className
		)}
		{...props}
	>
		<span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
			<SelectPrimitive.ItemIndicator>
				<Check className="w-4 h-4" />
			</SelectPrimitive.ItemIndicator>
		</span>

		<SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
	</SelectPrimitive.Item>
));
SelectItem.displayName = SelectPrimitive.Item.displayName;

const SelectSeparator = React.forwardRef(({ className, ...props }, ref) => (
	<SelectPrimitive.Separator ref={ref} className={cn('-mx-1 my-1 h-px bg-muted', className)} {...props} />
));
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;

export { Select, SelectGroup, SelectValue, SelectTrigger, SelectContent, SelectLabel, SelectItem, SelectSeparator };
