import React, { useMemo, useRef, useState, useEffect } from 'react';
import Loqate from '@/lib/utils/Loqate';
import { cn } from '@/lib/utils';
import { useDebounceEffect } from '@/hooks';
import Button from '@/components/atoms/button';
import { Select as SelectContainer, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';

const loqateLanguage = (language) => {
	const [languageCode] = language.replace('_', '-').split('-');
	return languageCode;
};

const CustomAddressSearch = (props) => {
	const { locale, countries, onSelect, limit, apiKey, className, classes, components, inline, debounce, apiUrl, value: inputValue } = props;

	const loqate = useMemo(() => Loqate.create(apiKey, apiUrl), [apiKey]);
	const [suggestions, setSuggestions] = useState([]);
	const [value, setValue] = useState(inputValue ?? '');
	const [selectedValue, setSelectedValue] = useState('');
	const [isSelectOpen, setIsSelectOpen] = useState(false);

	async function find(text, containerId) {
		const { Items } = await loqate.find({
			countries,
			limit,
			text,
			containerId,
			language: loqateLanguage(locale),
		});

		return Items ?? [];
	}

	async function selectSuggestion({ Type, Id }) {
		if (Type === 'Address') {
			const { Items = [] } = await loqate.retrieve(Id);

			// if (Items.length) {
			// setSuggestions([]);
			// }

			setSelectedValue(Items[0].Id);
			onSelect(Items[0]);
			setIsSelectOpen(false);
			return;
		}

		const items = await find(value, Id);
		setIsSelectOpen(true);
		setSuggestions(items);
	}

	async function handleChange({ target }) {
		const { value: search } = target;

		setValue(search);
	}

	useDebounceEffect(
		() => {
			if (value === '') {
				setSuggestions([]);
				setSelectedValue('');
				return;
			}

			find(value).then(setSuggestions);
		},
		debounce,
		[value]
	);

	const Input = components?.Input;

	return (
		<div className={cn('flex flex-col gap-2', className && className)} data-testid="react-loqate">
			<div className="flex flex-row items-stretch w-full gap-2">
				<Input
					className={cn(classes?.input)}
					onChange={handleChange}
					value={value}
					data-testid="react-loqate-input"
					onKeyDown={(e) => {
						if (e.key === 'Escape') {
							setSuggestions([]);
						}
					}}
				/>
				<Button
					type="button"
					variant="primary"
					className="shrink-0"
					disabled={!value}
					onClick={() => {
						find(value).then(setSuggestions);
					}}
				>
					Find address
				</Button>
			</div>

			<SelectContainer
				open={isSelectOpen}
				onOpenChange={(val) => {
					setIsSelectOpen(val);
				}}
				value={selectedValue}
				disabled={!suggestions.length}
				onValueChange={(val) => {
					const selected = suggestions.filter((sg) => sg.Id === val)[0];
					selectSuggestion(selected);
				}}
			>
				<SelectTrigger className={className} data-placeholder="Select address">
					<SelectValue placeholder="" />
				</SelectTrigger>

				<SelectContent data-testid="react-loqate-list" className="max-h-96">
					{suggestions.map((suggestion, i) => {
						return (
							<SelectItem key={suggestion.Id + i} data-testid={`react-loqate-list-item-${suggestion.Id}`} value={suggestion.Id} suggestion={suggestion}>
								{suggestion.Text} {suggestion.Description}
							</SelectItem>
						);
					})}
				</SelectContent>
			</SelectContainer>
		</div>
	);
};

export default CustomAddressSearch;
