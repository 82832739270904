import {
    LOQATE_BASE_URL,
    LOQATE_FIND_URL,
    LOQATE_RETRIEVE_URL
} from "./constants";

class Loqate {
    constructor(key, baseUrl = LOQATE_BASE_URL) {
        this.key = key;
        this.baseUrl = baseUrl;
    }

    static create(key, baseUrl = LOQATE_BASE_URL) {
        return new Loqate(key, baseUrl);
    }

    async retrieve(id) {
        const params = new URLSearchParams({ Id: id, Key: this.key });
        const url = `${this.baseUrl}/${LOQATE_RETRIEVE_URL}?${params.toString()}`;
        return fetch(url).then(r => r.json());
    }

    async find(query) {
        const { text, countries = [], containerId, language, limit } = query;

        const params = new URLSearchParams({
            Text: text,
            Countries: countries.join(","),
            language,
            Key: this.key
        });
        if (containerId) {
            params.set("Container", containerId);
        }
        if (limit) {
            params.set("limit", limit.toString());
        }
        const url = `${this.baseUrl}/${LOQATE_FIND_URL}?${params.toString()}`;
        const response = await fetch(url).then(r => r.json());

        const error = response?.Items?.find(item => item.Error);
        if (error) {
            throw new Error(`Loqate error: ${JSON.stringify(error)}`);
        }

        return response;
    }
}

export default Loqate;
