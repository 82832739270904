import React, { forwardRef, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FormItem as FormItemContainer, FormLabel, FormControl, FormDescription, FormMessage } from '@/components/ui/form';
import { cn } from '@/lib/utils';
import CustomAddressSearch from '@/components/CustomAddressSearch';
import { LOQATE_API_KEY } from '@/lib/utils/constants';
import { Switch } from '@/components/ui/switch';
import Input from '@/components/atoms/input';
import Textarea from '@/components/atoms/textarea';
import Tooltip from '../tooltip';
import DatePicker from '../datepicker';
import Select from '@/components/atoms/select';
import RadioGroup from '../radiogroup';
import Checkbox from '../checkbox';
import Icon from '@/components/atoms/icon';
import Text from '@/components/atoms/text';
import FileInput from '@/components/atoms/fileInput';
import RichTextEditor from '@/components/atoms/richTextEditor';
import Heading from '@/components/atoms/heading';
import { usePage } from '@inertiajs/react';
import Button from '@/components/atoms/button';

const FormItem = forwardRef(
	(
		{
			name,
			type,
			label,
			tooltip,
			description,
			placeholder,
			button,
			mode,
			options,
			htmlFor,
			disabled,
			className,
			inputClassName,
			checkboxWrapClassName,
			meta,
			value,
			step,
			hasFormItemTop,
			autoComplete,
			onChange,
			onPreparing,
			iconComponent,
			isFocused,
			validation,
			onBlur,
			setData,
			data,
			dataFormat,
			isHorizontal,
			uploadMultiple,
			encodedFile,
			showCurrencySymbol,
			fileName,
			fileExtension,
			disabledDays,
			onClick,
			onUploadedDone,
			readOnly,
			warning,
			isOneYearLimited,
			staffOnly,
			hasDateRange,
			id,
			showClear,
			min,
			max,
		},
		ref
	) => {
		let fieldElement = null;
		const input = ref ? ref : useRef();

		const { accountType } = usePage().props.auth;
		const isEIS = accountType === 'eis';

		useEffect(() => {
			if (isFocused && input.current) {
				input.current.focus();
			}
		}, []);

		if (
			type === 'text' ||
			type === 'email' ||
			type === 'number' ||
			type === 'password' ||
			type === 'tel' ||
			type === 'currency' ||
			type == 'datepicker-input'
		) {
			fieldElement = (
				<Input
					ref={input}
					type={type}
					name={name}
					step={step}
					placeholder={placeholder}
					button={button}
					disabled={disabled}
					value={value}
					onChange={(e) => (onChange ? onChange(e.target.value) : null)}
					className={cn(
						meta.info && meta.msg && 'border-notificationsInfo',
						meta.touched && meta.msg && 'border-notificationsError',
						meta.fieldEmpty && meta.msg && 'border-notificationsWarning',
						readOnly ? 'border-none' : 'border border-formOutline ',
						inputClassName
					)}
					iconComponent={iconComponent}
					showCurrencySymbol={showCurrencySymbol}
					onBlur={onBlur}
					readOnly={readOnly}
					autoComplete={autoComplete}
					min={min}
					max={max}
				/>
			);
		} else if (type === 'textarea') {
			fieldElement = (
				<Textarea
					name={name}
					placeholder={placeholder}
					disabled={disabled}
					value={value}
					onChange={(e) => (onChange ? onChange(e.target.value) : null)}
					className={cn(
						meta.info && meta.msg && 'border-notificationsInfo',
						meta.touched && meta.msg && 'border-notificationsError',
						meta.fieldEmpty && meta.msg && 'border-notificationsWarning',
						inputClassName,
						className
					)}
				/>
			);
		} else if (type === 'datepicker') {
			fieldElement = (
				<DatePicker
					mode={mode}
					disabled={disabled}
					onChange={(value) => (onChange ? onChange(value) : null)}
					placeholder={placeholder}
					className={cn(
						meta.info && meta.msg && 'border-notificationsInfo',
						meta.touched && meta.msg && 'border-notificationsError',
						meta.fieldEmpty && meta.msg && 'border-notificationsWarning',
						className,
						inputClassName
					)}
					// Note: pass "name" for <Calendar> to update the "setup_date" in its onSelect function
					name={name}
					// Note: pass "value" for <Calendar> to update "setup_date" in its onSelect function
					value={value}
					// Note: pass "setData" for <Calendar> to update "setup_date" in its onSelect function
					setData={setData}
					// Note: pass "dataFormat" for <Calendar> to update internal value in given format
					dataFormat={dataFormat}
					disabledDays={disabledDays}
					isOneYearLimited={isOneYearLimited}
					hasDateRange={hasDateRange}
					showClearButton={isEIS && mode === 'single'}
				/>
			);
		} else if (type === 'select') {
			fieldElement = (
				<Select
					name={name}
					options={options || []}
					disabled={disabled}
					defaultValue={value}
					value={value}
					onValueChange={(val) => (onChange ? onChange(val) : null)}
					placeholder={placeholder}
					className={cn(
						meta.info && meta.msg && 'border-notificationsInfo',
						meta.touched && meta.msg && 'border-notificationsError',
						meta.fieldEmpty && meta.msg && 'border-notificationsWarning',
						inputClassName
					)}
				/>
			);
		} else if (type === 'radiogroup') {
			fieldElement = (
				<RadioGroup
					name={name}
					options={options}
					disabled={disabled}
					defaultValue={value}
					value={value}
					onValueChange={(val) => (onChange ? onChange(val) : null)}
					className={cn(
						meta.info && meta.msg && 'border-notificationsInfo',
						meta.touched && meta.msg && 'border-notificationsError',
						meta.fieldEmpty && meta.msg && 'border-notificationsWarning'
					)}
					isHorizontal={isHorizontal}
				/>
			);
		} else if (type === 'checkbox' || type === 'pulldown-checkbox') {
			fieldElement = (
				<Checkbox
					id={id}
					name={name}
					htmlFor={htmlFor}
					label={label}
					disabled={disabled}
					checked={value}
					onCheckedChange={(val) => (typeof onChange === 'function' ? onChange(val) : null)}
					className={cn(
						meta.info && meta.msg && 'border-notificationsInfo',
						meta.touched && meta.msg && 'border-notificationsError',
						meta.fieldEmpty && meta.msg && 'border-notificationsWarning',
						className && className
					)}
					wrapClassName={checkboxWrapClassName}
				/>
			);
		} else if (type === 'plaintext') {
			fieldElement = <div dangerouslySetInnerHTML={{ __html: label }}></div>;
		} else if (type === 'file') {
			fieldElement = (
				<FileInput
					name={name}
					htmlFor={htmlFor}
					label={label}
					disabled={disabled}
					multiple={uploadMultiple}
					encodedFile={encodedFile}
					fileExtension={fileExtension}
					fileName={fileName}
					onChange={onChange}
					onPreparing={onPreparing}
					onUploadedDone={onUploadedDone}
					className={cn(
						meta.info && meta.msg && 'border-notificationsInfo',
						meta.touched && meta.msg && 'border-notificationsError',
						meta.fieldEmpty && meta.msg && 'border-notificationsWarning'
					)}
				/>
			);
		} else if (type === 'rich_text_editor') {
			fieldElement = (
				<RichTextEditor
					name={name}
					value={value}
					onChange={onChange}
					className={cn(
						meta.info && meta.msg && 'border-notificationsInfo',
						meta.touched && meta.msg && 'border-notificationsError',
						meta.fieldEmpty && meta.msg && 'border-notificationsWarning'
					)}
				/>
			);
		} else if (type === 'hr') {
			fieldElement = <div className="w-full h-px bg-formOutline"></div>;
		} else if (type === 'title') {
			fieldElement = (
				<div>
					<Heading type="h4">{label}</Heading>
					<div className="w-full h-px mt-5 bg-formOutline"></div>
				</div>
			);
		} else if (type === 'question_only') {
			fieldElement = (
				<div>
					<Heading type="h5" className="normal-case">
						{label}
					</Heading>
					<div className="w-full h-px mt-6 -mb-2 bg-formOutline"></div>
				</div>
			);
		} else if (type === 'checkboxgroup') {
			fieldElement = (
				<div className="flex flex-col">
					<div className="flex flex-row flex-wrap gap-4">
						{options?.map((option) => (
							<Checkbox
								htmlFor={`${name}_${option.value}`}
								label={option.label}
								disabled={false}
								checked={value === option.value}
								value={option.value}
								onCheckedChange={(val) => onChange(option.value, val)}
								{...(typeof value === 'object' && { checked: value?.includes(option.value) })}
							/>
						))}
					</div>
				</div>
			);
		} else if (type === 'checkbox_rich_text') {
			fieldElement = (
				<Checkbox
					wrapClassName="items-start"
					htmlFor={htmlFor}
					label={label}
					disabled={disabled}
					checked={value}
					onCheckedChange={(val) => onChange(val)}
					className={cn(
						'mt-1',
						meta.info && meta.msg && 'border-notificationsInfo',
						meta.touched && meta.msg && 'border-notificationsError',
						meta.fieldEmpty && meta.msg && 'border-notificationsWarning'
					)}
					showHtmlContent={true}
				/>
			);
		} else if (type === 'postcode_lookup') {
			fieldElement = (
				<CustomAddressSearch
					locale="en-GB"
					apiKey={LOQATE_API_KEY}
					components={{
						Input: (props) => <Input type="text" aria-label="Postcode Lookup" name={name} placeholder={placeholder} {...props} />,
					}}
					classes={{
						list: 'bg-white shadow-lg border w-full p-2 rounded-md max-h-96 overflow-y-auto mt-3 z-[99] pointer-events-auto !relative !top-0 !left-0',
						listItem: 'py-3 cursor-pointer hover:bg-gray-50 px-4 rounded-md',
					}}
					value={value}
					className="relative w-full grow disabled:bg-corporateGrey/30"
					onSelect={onChange}
					debounce={500}
					limit={10}
				/>
			);
		} else if (type === 'section_title') {
			fieldElement = (
				<div>
					<div className="w-full h-px mb-8 bg-formOutline"></div>
					<Heading className="md:absolute md:top-8 md:max-w-[26.466%] w-full" type="h4">
						{label}
					</Heading>
				</div>
			);
		}

		return (
			<FormItemContainer
				name={name}
				className={cn(
					'flex flex-col gap-4',
					warning && 'p-4 rounded-lg bg-notificationsWarningLight',
					staffOnly && 'p-4 rounded-lg bg-eis-light-blue-3',
					className
				)}
			>
				{type === 'switch' ? (
					<div className="flex items-center justify-between gap-2 p-4 border rounded-lg border-formOutline">
						<div className="flex flex-row items-start justify-between">
							<FormLabel name={name} className="font-normal">
								{label}
							</FormLabel>
						</div>

						<FormControl name={name}>
							<Switch value={value} setData={setData} data={data} name={name} onClick={onChange} />
						</FormControl>
					</div>
				) : (
					<>
						{hasFormItemTop && (label || tooltip || description) && (
							<div className={`flex flex-row justify-between items-end gap-2 ${disabled && 'opacity-50'}`}>
								<div className="flex flex-col gap-2">
									<div className="flex items-start justify-between gap-6">
										<div className="flex items-center gap-2">
											{tooltip && (
												<Tooltip
													trigger={<Icon name="info" className="text-eis-black w-eis-18 h-eis-18" />}
													content={tooltip.content}
													isModal={tooltip.isModal}
												/>
											)}
											{warning && <Icon name="warning" className="w-4 h-4 text-notificationsWarning" />}

											{label && type !== 'question_only' && (
												<FormLabel name={name} className="flex gap-1">
													{label} {validation === 'required' && <>*</>}
												</FormLabel>
											)}
										</div>
									</div>

									{description && (
										<FormDescription name={name} className="first-letter:uppercase text-corporateGrey">
											{description}
										</FormDescription>
									)}
								</div>
								{showClear && (
									<Button type="button" variant="small-button" size="small-button" onClick={() => onChange(null)} disabled={disabled}>
										Clear
									</Button>
								)}
							</div>
						)}

						<FormControl name={name}>
							<>
								{type === 'switch-without-border' ? (
									<Switch
										className={cn(
											meta.info && meta.msg && 'border-notificationsInfo',
											meta.touched && meta.msg && 'border-notificationsError',
											meta.fieldEmpty && meta.msg && 'border-notificationsWarning'
										)}
										value={value}
										setData={setData}
										data={data}
										name={name}
										onClick={onClick}
									/>
								) : (
									fieldElement
								)}
							</>
						</FormControl>
					</>
				)}

				<FormMessage name={name} meta={meta} />

				{warning && (
					<Text type="p" variant="bold" className="text-notificationsWarning">
						{warning}
					</Text>
				)}
			</FormItemContainer>
		);
	}
);

export default FormItem;

FormItem.propTypes = {
	name: PropTypes.string.isRequired,
	type: PropTypes.oneOf([
		'text',
		'email',
		'password',
		'number',
		'tel',
		'textarea',
		'datepicker',
		'datepicker-input',
		'select',
		'radiogroup',
		'checkbox',
		'switch',
		'switch-without-border',
		'plaintext',
		'file',
		'rich_text_editor',
		'currency',
		'hr',
		'pulldown',
		'pulldown-checkbox',
		'title',
		'checkboxgroup',
		'checkbox_rich_text',
		'postcode_lookup',
		'question_only',
		'section_title',
	]).isRequired,
	label: PropTypes.string,
	tooltip: PropTypes.shape({
		label: PropTypes.string,
		value: PropTypes.bool,
	}),
	description: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	placeholder: PropTypes.string,
	button: PropTypes.string,
	mode: PropTypes.oneOf(['date', 'time', 'datetime', 'single', 'range']),
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		})
	),
	htmlFor: PropTypes.string,
	validation: PropTypes.string,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	inputClassName: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object]),
	onChange: PropTypes.func,
	onPreparing: PropTypes.func,
	meta: PropTypes.shape({
		touched: PropTypes.bool,
		msg: PropTypes.string,
		info: PropTypes.bool,
		plaintext: PropTypes.string,
		fieldEmpty: PropTypes.bool,
	}),
	hasFormItemTop: PropTypes.bool,
	iconComponent: PropTypes.element,
	isFocused: PropTypes.bool,
	setData: PropTypes.func,
	data: PropTypes.object,
	dataFormat: PropTypes.string,
	isHorizontal: PropTypes.bool,
	uploadMultiple: PropTypes.bool,
	showCurrencySymbol: PropTypes.bool,
	staffOnly: PropTypes.bool,
	showClear: PropTypes.bool,
};

FormItem.defaultProps = {
	tooltip: null,
	description: null,
	placeholder: null,
	button: null,
	mode: null,
	options: null,
	htmlFor: null,
	validation: null,
	disabled: false,
	className: null,
	showClear: false,
	inputClassName: null,
	value: null,
	onChange: null,
	meta: {
		touched: false,
		msg: null,
		info: null,
		plaintext: null,
		fieldEmpty: false,
	},
	hasFormItemTop: true,
	isFocused: false,
	setData: null,
	data: null,
	isHorizontal: false,
	uploadMultiple: true,
	showCurrencySymbol: false,
	staffOnly: false,
};
