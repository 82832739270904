import React, { useRef, useEffect, useState } from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import { cn } from '@/lib/utils';
import Dropzone from 'react-dropzone-uploader';
import { getDroppedOrSelectedFiles } from 'html5-file-selector';

// Note: add custom styles for react-dropzone-uploader
import '../../../../../resources/css/react-dropzone-uploader.css';

import Icon from '@/components/atoms/icon';
import Text from '@/components/atoms/text';
import Button from '@/components/atoms/button';

import { FormLabel } from '@/components/ui/form';

const FileInput = ({ multiple, disabled, fileName, fileExtension, encodedFile, onChange, onPreparing, onUploadedDone, ...props }) => {
	const [blobData, setBlobData] = useState([]);

	const extensionToType = (extnesion) => {
		let fileType = 'application/pdf';
		switch (extnesion) {
			case '.jpeg':
			case '.jpg':
				fileType = 'image/jpeg';
				break;
			case '.png':
				fileType = 'image/png';
				break;
			case '.doc':
				fileType = 'application/msword';
				break;
			case '.docx':
				fileType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
				break;
			default:
				break;
		}
		return fileType;
	};

	useEffect(() => {
		if (!blobData.length && encodedFile) {
			fetch(encodedFile).then((res) => {
				res.arrayBuffer().then((buf) => {
					const file = new File([buf], fileName, { type: extensionToType(fileExtension) });
					setBlobData([file]);
				});
			});
		}
	}, [encodedFile]);

	const Layout = ({ input, previews, dropzoneProps }) => {
		return (
			<div className="flex flex-col gap-8">
				<div {...dropzoneProps}>{input}</div>

				{previews.length > 0 && (
					<div className="flex flex-col gap-8">
						<FormLabel name="Attachments">Attachments</FormLabel>

						{previews}
					</div>
				)}
			</div>
		);
	};

	const handleChangeStatus = ({ meta, file }, status) => {
		switch (status) {
			case 'done':
				if (!multiple) {
					const reader = new FileReader();
					reader.onload = (event) => {
						onChange(file, event.target.result);

						if (typeof onPreparing === 'function') onPreparing(false);
					};
					reader.readAsDataURL(file);
				}
				break;

			case 'preparing':
				if (!multiple && typeof onPreparing === 'function') onPreparing(true);
				break;

			case 'error':
				if (!multiple && typeof onPreparing === 'function') onPreparing(false);
				break;
		}
	};

	const getFilesFromEvent = async (e) => {
		const chosenFiles = await getDroppedOrSelectedFiles(e);
		return chosenFiles.map((f) => f.fileObject);
	};

	const Preview = ({ meta, files }) => {
		const { name, size, type, status } = meta;

		// Note: convert file size from bytes to mega bytes
		const fileSize = `${(size / 1000000).toFixed(1)}MB`;

		// Note: extract file type
		const fileType = `${type.split('/')[1].toUpperCase()}`;

		// Set the text content
		const fileTypeAndSize = `${fileType}, ${fileSize}`;

		const handleRemoveBtn = (e) => {
			const parentElem = e.currentTarget.parentElement;
			const clickedFileName = parentElem.querySelector('.file-name').textContent;
			let itemIndex = null;

			const findIndex = (item, index) => {
				if (item.file.name === clickedFileName) {
					itemIndex = index;
				}
			};

			files.find((item, index) => findIndex(item, index));

			files[itemIndex].remove();

			// trigger form change
			if (!multiple) onChange(null, null);
		};

		return (
			<div className="flex items-center justify-between gap-4 p-6 border rounded-lg border-formOutline">
				<Icon name="document-text" className="text-eis-black shink-0" />

				<div className="flex flex-col gap-1.5 w-full">
					<Text type="p" variant="bold" className="file-name text-eis-black">
						{name}
					</Text>

					<Text type="small" variant="normal" className="text-corporateLightBlueDarkHint">
						{fileTypeAndSize} {status === 'preparing' && '. preparing'}
					</Text>
				</div>

				<Button
					variant="circle-icon"
					size="circle-icon"
					icon="close"
					onClick={(e) => handleRemoveBtn(e)}
					className="shrink-0"
					type="button"
					disabled={status === 'preparing'}
				/>
			</div>
		);
	};

	const customInput = ({ accept, onFiles, files, getFilesFromEvent }) => {
		const isDisabled = multiple ? disabled : files.length === 1;
		onUploadedDone && onUploadedDone(files);

		return (
			<label
				className={cn(
					'flex flex-col items-center justify-center w-full px-6 py-8 bg-white cursor-pointer hover:bg-corporateGreyLight',
					isDisabled && 'opacity-50 cursor-not-allowed hover:bg-white'
				)}
			>
				<div className="flex flex-col items-center justify-center gap-2">
					<Icon name="document-upload" className="text-eis-black" />

					<Text type="p" variant="bold">
						<span>Drag & Drop or </span>
						<span className="underline text-corporateBlueBright">Choose file</span>
						<span> to upload</span>
					</Text>
				</div>

				<input
					style={{ display: 'none' }}
					type="file"
					accept={accept}
					multiple={multiple}
					disabled={isDisabled}
					onChange={async (e) => {
						const chosenFiles = await getFilesFromEvent(e);
						onFiles(chosenFiles);
					}}
				/>
			</label>
		);
	};

	return (
		<Dropzone
			accept="image/*, .pdf, .doc, .docx"
			multiple={multiple}
			initialFiles={blobData}
			onChangeStatus={handleChangeStatus}
			LayoutComponent={Layout}
			InputComponent={customInput}
			PreviewComponent={Preview}
			getFilesFromEvent={getFilesFromEvent}
			// getUploadParams={getUploadParams}
			// onSubmit={handleSubmit}
		/>
	);
};

export default FileInput;

FileInput.propTypes = {};
FileInput.defaultProps = {};
